import React from 'react';
import { Spin } from 'antd';

import styles from './app-loader.module.scss';

export const AppLoader: React.FC = () => {
  return (
    <div className={styles.container}>
      <Spin className={styles.loader} />
    </div>
  )
};
