import { axiosInstance } from 'app/constants/axios-instance';
import { User } from 'app/models/user';
import { SessionCreate } from 'app/models/session-create';

export const createSession = (sessionCreate: SessionCreate) =>
  axiosInstance
    .post<void>('/session', sessionCreate)
    .then((response) => response.data);

export const deleteSession = () =>
  axiosInstance
    .delete<void>('/session')
    .then((response) => response.data);

export const getCurrentUser = () =>
  axiosInstance
    .get<User>('/session/user')
    .then((response) => response.data);
