import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from './app';
import { AppLoader } from './components/loader/app-loader';
import { AppConfig } from './constants/app-config';

import './i18n-config';

import './index.less';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter basename={AppConfig.publicUrl}>
      <React.Suspense fallback={<AppLoader />}>
        <App />
      </React.Suspense>
    </BrowserRouter>
  </React.StrictMode>
);
