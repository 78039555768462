export const SLUG = {
  LOGIN: 'login',
  CUSTOMER: 'customer',
  DOCUMENT: 'document',
  DOCUMENT_PREVIEW: 'preview',
  DOCUMENT_PREVIEW_LIST: 'documents',
  BALANCE: 'balance',
  TEMPLATE: 'template',
  RULE: 'rule',
  TAG: 'tag',
  NOTIFICATION: 'notification',
  SETTINGS: 'settings',
  TOKEN: ':token',
};

export const PATH = {
  ROOT_PAGE: '/',
  LOGIN_PAGE: `/${SLUG.LOGIN}`,
  CUSTOMER_PAGE: `/${SLUG.CUSTOMER}`,
  DOCUMENT_PAGE: `/${SLUG.DOCUMENT}`,
  DOCUMENT_PREVIEW_PAGE: `/${SLUG.DOCUMENT_PREVIEW}/${SLUG.TOKEN}`,
  DOCUMENT_PREVIEW_LIST_PAGE: `/${SLUG.DOCUMENT_PREVIEW}/${SLUG.TOKEN}/${SLUG.DOCUMENT_PREVIEW_LIST}`,
  BALANCE_PAGE: `/${SLUG.BALANCE}`,
  TEMPLATE_PAGE: `/${SLUG.TEMPLATE}`,
  RULE_PAGE: `/${SLUG.RULE}`,
  TAG_PAGE: `/${SLUG.TAG}`,
  NOTIFICATION_PAGE: `/${SLUG.NOTIFICATION}`,
  SETTINGS_PAGE: `/${SLUG.SETTINGS}`,
};
