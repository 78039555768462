import React from 'react';

import { User } from './models/user';

export interface AppContextValue {
  user: User | null;
}

export const AppContext = React.createContext<AppContextValue>({
  user: null,
});
